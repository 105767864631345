import axios from 'axios';
import {
  formatBillMonth,
  formatServiceType,
  replaceServiceType,
  camelCaseWithSpace
} from '@/util/dataFormatting';
import { displayMessage } from '@/util/utils';

const state = {
  locationsOverviewChart: [],
  locationsTotalAccountsChart: [],
  locationsTotalChargesChart: [],
  locationsAttentionItems: {},
  locationsChargesChart: [],
  locationsUsagesChart: [],
  states: [],
  locations: [],
  companyCustomAttributes: [],
  locationsColumns: [],
  customAttributesExist: false,
  isLocationsChartsLoad: true,
  isLocationsAttentionItemsLoad: true,
  isLocationsUsagesChartLoad: true,
  isLocationsGridLoad: true,
  locationCompanyId: null,
  isSitesEnergyStarActive: {},
  error: null,
  associatedTags: [],
  companyTags: []
};

const mutations = {
  SET_LOCATIONS_OVERVIEW_CHART(state, data) {
    state.locationsOverviewChart = data;
  },
  SET_LOCATIONS_TOTAL_ACCOUNTS_CHART(state, data) {
    state.locationsTotalAccountsChart = data;
  },
  SET_LOCATIONS_TOTAL_CHARGES_CHART(state, data) {
    state.locationsTotalChargesChart = data;
  },
  SET_LOCATIONS_ATTENTION_ITEMS(state, data) {
    state.locationsAttentionItems = data;
  },
  SET_LOCATIONS_CHARGES_CHART(state, data) {
    state.locationsChargesChart = data;
  },
  SET_LOCATIONS_USAGES_CHART(state, data) {
    state.locationsUsagesChart = data;
  },
  SET_LOCATIONS_STATES(state, data) {
    state.states = data;
  },
  SET_LOCATIONS(state, data) {
    state.locations = data;
  },
  SET_LOCATIONS_COLUMNS(state, data) {
    state.locationsColumns = data;
  },
  SET_LOCATIONS_CHARTS_LOAD(state, load) {
    state.isLocationsChartsLoad = load;
  },
  SET_LOCATIONS_ATTENTION_ITEMS_LOAD(state, load) {
    state.isLocationsAttentionItemsLoad = load;
  },
  SET_LOCATIONS_USAGES_CHART_LOAD(state, load) {
    state.isLocationsUsagesChartLoad = load;
  },
  SET_LOCATIONS_LOAD(state, load) {
    state.isLocationsGridLoad = load;
  },
  SET_LOCATION_COMPANY_ID(state, id) {
    state.locationCompanyId = id;
  },
  SET_SITE_ENERGY_STAR_ACTIVE(state, data) {
    state.isSitesEnergyStarActive = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_COMPANY_CUSTOM_ATTRIBUTES(state, data) {
    state.companyCustomAttributes = data;
  },
  SET_COMPANY_CUSTOM_ATTRIBUTES_RAW(state, data) {
    state.companyCustomAttributes = data;
  },
  SET_COMPANY_CUSTOM_ATTRIBUTES_EXIST(state, data) {
    state.customAttributesExist = data;
  },
  SET_ASSOCIATED_TAGS(state, data) {
    if (data?.length) {
      let associations = data;
      let associatedTags = associations.map((res) => {
        return state.companyTags.find((ct) => ct.id == res.tagId);
      });
      state.associatedTags = sortArrayByKey(associatedTags, 'text');
    } else {
      state.associatedTags = [];
    }
  },
  SET_COMPANY_TAGS(state, data) {
    state.companyTags = data;
  },
  SET_COMPANY_TAGS_LOADING(state, data) {
    state.isCompanyTagLoading = data;
  },
  HANDLE_MOVE_SELECTION(
    state,
    { sourceCollection, targetCollection, tagIds, moveAll = false }
  ) {
    const moveTag = (tagIndex) => {
      const [movedTag] = state[sourceCollection].splice(tagIndex, 1);
      state[targetCollection]?.push(movedTag);
      const allItemsInTarget = state[targetCollection];
      if (allItemsInTarget?.length) {
        let uniqueItems = uniqueArrayByKey(allItemsInTarget, 'id');
        uniqueItems = sortArrayByKey(uniqueItems, 'text');
        state[targetCollection] = uniqueItems;
      }
    };
    const getTagIndex = (tagId) =>
      state[sourceCollection]?.findIndex((tag) => tag.id === tagId);
    if (moveAll && !tagIds?.length) {
      const activeTags = state[sourceCollection]?.filter((tag) => tag.isActive);
      activeTags?.forEach((tag) => {
        const tagIndex = getTagIndex(tag.id);
        if (tagIndex >= 0) moveTag(tagIndex);
      });
    } else {
      tagIds?.forEach((tagId) => {
        const tagIndex = getTagIndex(tagId);
        if (tagIndex >= 0) moveTag(tagIndex);
      });
    }
  },
  UPDATE_DRAG_DROP_SELECTION(state, { collection, dropResult }) {
    state[collection] = applyDragAndDrop(state[collection], dropResult);
  }
};

const actions = {
  async setLocationsOverviewChart({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations/overview`
          : `/locations/overview`
      )
      .then((res) => {
        if (res.status === 200) {
          let overview = [];
          res.data.data.overview.map((element) => {
            for (const property in element) {
              overview.push({
                type: `${property.replace('total', '')}`,
                total: element[property]
              });
            }
          });
          overview = overview.sort((first, second) => {
            return second.total - first.total;
          });
          commit('SET_LOCATIONS_OVERVIEW_CHART', overview);
          commit('SET_LOCATIONS_CHARTS_LOAD', false);
          commit('SET_LOCATION_COMPANY_ID', url.companyId);
        }
      })
      .catch((err) => {
        console.log('Error: ', err.response);
        commit('SET_ERROR', err.response);
      });
  },
  async setSiteEnergyStarAccess({ commit }, params) {
    return await axios
      .put(
        `locations/${params.rowId}/energyStarToggle?isEnergyStarActive=${params.value}`
      )
      .then((res) => res)
      .catch((err) => {
        console.log(
          'ERROR: ${err.status} - ${err.message} Failed to update es_isActive in the sites table'
        );
        commit('SET_ERROR', err.status);
      });
  },
  async setLocationsTotalAccountsChart({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `companies/${url.companyId}/locations/totalNumberOfAccountsByServiceTypes`
          : `locations/totalNumberOfAccountsByServiceTypes`
      )
      .then((res) => {
        if (res.status === 200) {
          let totalAccounts;
          if (res.data.data.totalNumberOfAccountsByServiceTypes > 1) {
            totalAccounts =
              res.data.data.totalNumberOfAccountsByServiceTypes.sort(
                (first, second) => {
                  if (Boolean(formatServiceType(first.serviceType))) {
                    first.serviceType = formatServiceType(first.serviceType);
                  }
                  if (Boolean(formatServiceType(second.serviceType))) {
                    second.serviceType = formatServiceType(second.serviceType);
                  }
                  return second.totalAccounts - first.totalAccounts;
                }
              );
          } else {
            totalAccounts =
              res.data.data.totalNumberOfAccountsByServiceTypes.map((item) => {
                if (Boolean(formatServiceType(item.serviceType))) {
                  item.serviceType = formatServiceType(item.serviceType);
                }
                return item;
              });
          }
          commit('SET_LOCATIONS_TOTAL_ACCOUNTS_CHART', totalAccounts);
          commit('SET_LOCATIONS_CHARTS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log('Error: ', err.response);
        commit('SET_ERROR', err.response);
      });
  },
  async setLocationsSpendByServiceType({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations/totalSpendByServiceType`
          : '/locations/totalSpendByServiceType'
      )
      .then((res) => {
        if (res.status === 200) {
          const chargesChart = res.data.data.totalSpendByServiceType.sort(
            (first, second) => {
              first.billMonth = formatBillMonth(first.billMonth, true);
              second.billMonth = formatBillMonth(second.billMonth, true);
              return new Date(first.billMonth) - new Date(second.billMonth);
            }
          );

          commit('SET_LOCATIONS_CHARGES_CHART', chargesChart);
          commit('SET_LOCATIONS_CHARTS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log('Error: ', err.response);
        commit('SET_ERROR', err.response);
      });
  },
  async setLocationsSpendByServiceTypeIn12Months({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations/totalSpendByServiceTypeIn12Months`
          : '/locations/totalSpendByServiceTypeIn12Months'
      )
      .then((res) => {
        if (res.status === 200) {
          const totalChargesChart =
            res.data.data.totalSpendByServiceTypeIn12Months.filter(
              (element) => {
                element.serviceType = formatServiceType(element.serviceType);
                return Boolean(element.serviceType);
              }
            );
          commit('SET_LOCATIONS_TOTAL_CHARGES_CHART', totalChargesChart);
          commit('SET_LOCATIONS_CHARTS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log('Error: ', err.response);
        commit('SET_ERROR', err.response);
      });
  },
  async setLocationsAttentionItems({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations/attention`
          : '/locations/attention'
      )
      .then((res) => {
        if (res.status === 200) {
          const attentionItems = res.data.data[0];
          commit('SET_LOCATIONS_ATTENTION_ITEMS', attentionItems);
          commit('SET_LOCATIONS_ATTENTION_ITEMS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log('Error: ', err.response);
        commit('SET_ERROR', err.response);
      });
  },
  async setLocationsUsages({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations/usage`
          : '/locations/usage'
      )
      .then((res) => {
        if (res.status === 200) {
          let usagesChart = [];
          res.data.data.totalConsumptionByServiceType
            .sort(
              (first, second) =>
                new Date(first.billMonth) - new Date(second.billMonth)
            )
            .map((item) => {
              if (!Boolean(item.energyUnit)) {
                return;
              }

              item.billMonth = formatBillMonth(item.billMonth, true);
              const found = usagesChart.find((el) => {
                return el.billMonth === item.billMonth;
              });

              if (found && !Boolean(found[item.serviceType])) {
                usagesChart.map((element) => {
                  if (element === found) {
                    element[item.serviceType] = item.totalUsage;
                    if (item.serviceType === 'electric') {
                      element['kVA'] = item.maxKvA;
                      element['kW'] = item.maxkW;
                      element['kVAR'] = item.totalKvAR;
                    }
                  }
                });
              }
              // pushing values if billMonth doesn't exist in usagesChart
              else {
                if (item.serviceType === 'electric') {
                  usagesChart.push({
                    billMonth: item.billMonth,
                    [item.serviceType]: item.totalUsage,
                    kVA: item.maxKvA,
                    kW: item.maxkW,
                    kVAR: item.totalKvAR
                  });
                } else {
                  usagesChart.push({
                    billMonth: item.billMonth,
                    [item.serviceType]: item.totalUsage
                  });
                }
              }
            });
          commit('SET_LOCATIONS_USAGES_CHART', usagesChart);
          commit('SET_LOCATIONS_USAGES_CHART_LOAD', false);
        }
      })
      .catch((err) => {
        console.log('Error: ', err.response);
        commit('SET_ERROR', err.response);
      });
  },
  async setStates({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations/states`
          : `/locations/states`
      )
      .then((res) => {
        if (res.status === 200) {
          const states = res.data.data.map((item) => {
            switch (item.state) {
              case 'Ontario':
                item.fullStateName = item.state;
                break;
              case 'PR':
                item.fullStateName = 'Puerto Rico';
                break;
            }
            return item;
          });
          commit('SET_LOCATIONS_STATES', states);
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.clear();
        commit('SET_ERROR', err.status);
      });
  },
  async setLocations({ commit }, url) {
    await axios
      .get(
        url.isConsultant
          ? `/companies/${url.companyId}/locations`
          : '/locations'
      )
      .then((res) => {
        if (res.status === 200) {
          let locations = res.data.data;
          commit('SET_LOCATIONS_COLUMNS', []);
          commit('SET_COMPANY_CUSTOM_ATTRIBUTES_EXIST', false);
          const columns = Object.keys(locations[0]);
          if (columns.length > 20) {
            //Custom Attributes Exist
            commit('SET_COMPANY_CUSTOM_ATTRIBUTES_EXIST', true);

            let customAttributes = [];
            for (let i = 20; i <= columns.length - 1; i++) {
              customAttributes.push({
                prop: columns[i],
                name: columns[i].toUpperCase(),
                sortable: true,
                width: 120
              });
            }
            commit('SET_LOCATIONS_COLUMNS', customAttributes);
          }
          locations.map((location) => {
            location.serviceIcons = replaceServiceType(location.services, true);
            location.services = replaceServiceType(location.services);
            location.mapSearchItem = `${location.name} | ${location.address} ${location.city}, ${location.state} ${location.zip}`;
          });
          commit('SET_LOCATIONS', locations);
          commit('SET_LOCATIONS_LOAD', false);
        }
      })
      .catch((err) => {
        console.log('Error: ', err.response);
        commit('SET_ERROR', err.response);
      });
  },
  async setLocationsLoads({ commit }) {
    commit('SET_LOCATIONS_CHARTS_LOAD', true);
    commit('SET_LOCATIONS_ATTENTION_ITEMS_LOAD', true);
    commit('SET_LOCATIONS_LOAD', true);
  },
  async setCompanyCustomAttributes({ commit }, params) {
    await axios
      .get(`companies/${params.companyId}/companyCustomAttribute`)
      .then((res) => {
        if (params.rawData) {
          commit('SET_COMPANY_CUSTOM_ATTRIBUTES_RAW', res.data.data);
          return;
        }
        const customAttributes = res.data.data.map((item) => {
          const { attributeName } = item;
          const customAttributeName = camelCaseWithSpace(attributeName);

          const customAttribute = {
            prop: customAttributeName,
            name: attributeName.toUpperCase(),
            sortable: true,
            width: 120
          };
          return customAttribute;
        });
        commit('SET_COMPANY_CUSTOM_ATTRIBUTES', customAttributes);
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  },
  async updateCompanyCustomAttributes({ commit }, params) {
    return await axios
      .put(`${params.companyId}/editCustomAttributes`, params.customAttributes)
      .then((res) => res)
      .catch((err) => {
        console.error('Error: ', err.response);
        commit('SET_ERROR', err.status);
      });
  },
  async setAssociatedTags({ commit }, siteId) {
    try {
      const res = await axios.get(`/site/${siteId}/associateTags`);
      if (res.data.data) {
        commit('SET_ASSOCIATED_TAGS', res.data.data);
      } else {
        commit('SET_ASSOCIATED_TAGS', []);
      }
    } catch (error) {}
  },
  async setCompanyTags({ commit }, companyId) {
    try {
      commit('SET_COMPANY_TAGS_LOADING', true);
      const apiResponse = await axios.get(`/companies/${companyId}/tags`);
      if (apiResponse.status == 200) {
        let companyTags = apiResponse.data.data;
        companyTags = sortArrayByKey(companyTags, 'text');
        commit('SET_COMPANY_TAGS', companyTags);
      } else {
        commit('SET_COMPANY_TAGS', []);
      }
    } catch (error) {
      console.log(error);
      throw Error(error);
    } finally {
      commit('SET_COMPANY_TAGS_LOADING', false);
    }
  },
  async associateTags({ commit }, { companyId, siteId, tagIds }) {
    try {
      commit('SET_COMPANY_TAGS_LOADING', true);
      await axios.post(
        `/companies/${companyId}/site/${siteId}/associateTags`,
        tagIds
      );
      displayMessage('Tags associated successfully!', 'success');
    } catch (error) {
      console.log(error);
      throw Error(error);
    } finally {
      commit('SET_COMPANY_TAGS_LOADING', false);
    }
  }
};

const getters = {
  locationsOverviewChart: (state) => {
    return state.locationsOverviewChart;
  },
  locationsTotalAccountsChart: (state) => {
    return state.locationsTotalAccountsChart;
  },
  locationsTotalChargesChart: (state) => {
    return state.locationsTotalChargesChart;
  },
  locationsChargesChart: (state) => {
    return state.locationsChargesChart;
  },
  locationsUsagesChart: (state) => {
    return state.locationsUsagesChart;
  },
  locationsAttentionItems: (state) => {
    return state.locationsAttentionItems;
  },
  states: (state) => {
    return state.states;
  },
  locations: (state) => {
    return state.locations;
  },
  isLocationsChartsLoad: (state) => {
    return state.isLocationsChartsLoad;
  },
  isLocationsAttentionItemsLoad: (state) => {
    return state.isLocationsAttentionItemsLoad;
  },
  isLocationsUsagesChartLoad: (state) => {
    return state.isLocationsUsagesChartLoad;
  },
  isLocationsGridLoad: (state) => {
    return state.isLocationsGridLoad;
  },
  getCustomColumns: (state) => {
    return state.locationsColumns;
  },
  isSitesEnergyStarActive: (state) => {
    return state.isSitesEnergyStarActive;
  },
  getLocationCompanyId: (state) => {
    return state.locationCompanyId;
  },
  getCompanyCustomAttributes: (state) => {
    return state.companyCustomAttributes;
  },
  getRawCompanyCustomAttributes: (state) => {
    return state.companyCustomAttributes;
  },
  doCustomAttributesExist: (state) => {
    return state.customAttributesExist;
  },
  getAssociatedTags: (state) => {
    return state.associatedTags;
  },
  getCompanyTags: (state) => {
    return state.companyTags?.filter((tag) => tag.isActive);
  }
};

const applyDragAndDrop = (collection, dropResult) => {
  const { removedIndex, addedIndex, payload } = dropResult;
  if (removedIndex === null && addedIndex === null) return collection;
  const result = [...collection];
  let itemToAdd = payload;
  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }
  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }
  let uniqueItems = [];
  if (result?.length) {
    uniqueItems = uniqueArrayByKey(result, 'id');
    uniqueItems = sortArrayByKey(uniqueItems, 'text');
  }
  return uniqueItems;
};

const sortArrayByKey = (arrayOfObjects, key) => {
  return arrayOfObjects.sort((a, b) => {
    return a[key]?.localeCompare(b[key], undefined, {
      numeric: true,
      sensitivity: 'base'
    });
  });
};

const uniqueArrayByKey = (arrayOfObjects, key) => {
  return arrayOfObjects.filter(
    (item, index, self) => index === self.findIndex((i) => i[key] === item[key])
  );
};

export default {
  state,
  mutations,
  actions,
  getters
};
