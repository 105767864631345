import axios from 'axios';
import {
  capitalizeFirstLetter,
  formatBillMonth,
  formatSortingBillMonth,
  formatDate,
  replaceServiceType,
  formatServiceType
} from '@/util/dataFormatting';
import { deepCopy } from '@/components/Table/utils/index';

const state = {
  accountInfo: {},
  accountLatestBillInfo: {},
  accountChargesChart: [],
  accountUsagesChart: [],
  accountChargesGrid: [],
  accountUsagesGrid: [],
  accountServiceTypes: [],
  accountMeters: [],
  isAccountChartsLoad: true,
  isAccountChargesGridLoad: true,
  isAccountUsagesGridLoad: true,
  isAccountMetersGridLoad: true,
  accountTotalCharges: '',
  accountTotalUsages: {},
  accountTotalChargesDate: '',
  accountTotalUsagesDate: '',
  error: null
};

const mutations = {
  SET_ACCOUNT_INFO(state, data) {
    state.accountInfo = data;
  },
  SET_ACCOUNT_LATEST_BILL_INFO(state, data) {
    state.accountLatestBillInfo = data;
  },
  SET_ACCOUNT_CHARGES_CHART(state, data) {
    state.accountChargesChart = data;
  },
  SET_ACCOUNT_USAGES_CHART(state, data) {
    state.accountUsagesChart = data;
  },
  SET_ACCOUNT_CHARGES_GRID(state, data) {
    state.accountChargesGrid = data;
  },
  SET_ACCOUNT_USAGES_GRID(state, data) {
    state.accountUsagesGrid = data;
  },
  SET_ACCOUNT_SERVICE_TYPES(state, data) {
    state.accountServiceTypes = data;
  },
  SET_ACCOUNT_METERS(state, data) {
    state.accountMeters = data;
  },
  SET_ACCOUNT_CHARTS_LOAD(state, load) {
    state.isAccountChartsLoad = load;
  },
  SET_ACCOUNT_CHARGES_GRID_LOAD(state, load) {
    state.isAccountChargesGridLoad = load;
  },
  SET_ACCOUNT_USAGES_GRID_LOAD(state, load) {
    state.isAccountUsagesGridLoad = load;
  },
  SET_ACCOUNT_METERS_GRID_LOAD(state, load) {
    state.isAccountMetersGridLoad = load;
  },
  SET_ACCOUNT_TOTAL_CHARGES(state, data) {
    state.accountTotalCharges = data;
  },
  SET_ACCOUNT_TOTAL_USAGES(state, data) {
    state.accountTotalUsages = data;
  },
  SET_ACCOUNT_TOTAL_CHARGES_DATE(state, data) {
    state.accountTotalChargesDate = data;
  },
  SET_ACCOUNT_TOTAL_USAGES_DATE(state, data) {
    state.accountTotalUsagesDate = data;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async setAccountInfo({ commit }, accountId) {
    await axios
      .get(`/accounts/${accountId}/info`)
      .then((res) => {
        if (res.status === 200) {
          let accountInfo = res.data.data.account[0];
          accountInfo.nextExpectedDeliveryDate = formatDate(
            accountInfo.nextExpectedDeliveryDate
          );

          accountInfo.meters = res.data.data.meters;
          accountInfo.meters.map((item) => {
            item.serviceIcon = replaceServiceType(item.meterService, true);
            return item;
          });

          //retrieving account meter numbers
          accountInfo.meterNumbers = [];
          accountInfo.meters.forEach((meter) => {
            if (Boolean(meter.number)) {
              accountInfo.meterNumbers.push(meter.number);
            }
          });

          //retrieving account meterEsiIds
          accountInfo.meterEsiIds = {};
          let meterServiceItem;
          accountInfo.meters.map((item, idx) => {
            if (!(item.meterService in accountInfo.meterEsiIds)) {
              accountInfo.meterEsiIds[item.meterService] = {
                serviceIcon: replaceServiceType(item.meterService, true),
                esiIdDisplay: '',
                serviceEsiIds: [],
                editableServiceEsiIds: []
              };
            }
            meterServiceItem = accountInfo.meterEsiIds[item.meterService];
            if (
              Boolean(meterServiceItem) &&
              !Boolean(meterServiceItem.serviceEsiIds.includes(item.esiId))
            ) {
              meterServiceItem.serviceEsiIds.push(item.esiId);
            }
            if (
              Boolean(item.esiId) &&
              !Boolean(meterServiceItem.esiIdDisplay)
            ) {
              meterServiceItem.esiIdDisplay = item.esiId;
            }
            //Used to show the meters and their ESI Id in the edit modal
            meterServiceItem.editableServiceEsiIds.push(item);

            /* The API query sets to 'Undefined'; changing here in case a table or SP need the 'Undefined' value */
            if (item.number === 'Undefined') {
              item.number = 'No Number';
            }
          });

          if (!Boolean(accountInfo.serviceAddress)) {
            accountInfo.serviceAddress = accountInfo.locationAddress;
          }
          commit('SET_ACCOUNT_INFO', accountInfo);
          commit('SET_ACCOUNT_METERS_GRID_LOAD', false);
          commit('SET_ACCOUNT_METERS', accountInfo.meters);
          commit('SET_ACCOUNT_CHARTS_LOAD', false);
        }
      })
      .catch((err) => {
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountTotalSpendByMonth({ commit }, accountId) {
    await axios
      .get(`/accounts/${accountId}/info/bills`)
      .then((res) => {
        if (res.status === 200) {
          const latestBillInfo = Boolean(res.data.data.totalSpendByMonths[0])
            ? {
                billMonth: formatBillMonth(
                  res.data.data.totalSpendByMonths[0].billMonth,
                  true
                ),
                dueDate: formatDate(res.data.data.totalSpendByMonths[0].dueDate)
              }
            : null;
          commit('SET_ACCOUNT_LATEST_BILL_INFO', latestBillInfo);
          const accountChargesGrid = res.data.data.totalSpendByMonths
            .map((item) => {
              item.billMonth = formatSortingBillMonth(item.billMonth);
              item.statusDate =
                item.status?.toLowerCase() === 'cleared'
                  ? formatDate(item.statusDate)
                  : '';
              return item;
            })
            .sort((first, second) => {
              //Sort newest to oldest
              return new Date(second.billMonth) - new Date(first.billMonth);
            });
          commit('SET_ACCOUNT_CHARGES_GRID', accountChargesGrid);
          commit('SET_ACCOUNT_CHARGES_GRID_LOAD', false);
        }
      })
      .catch((err) => {
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountChargesChart({ commit }, accountId) {
    await axios
      .get(`/accounts/${accountId}/info/totalSpendByServiceType`)
      .then((res) => {
        if (res.status === 200) {
          let account12MonthsTotalCharge = 0;
          const accountChargesChart = deepCopy(
            res.data.data.totalSpendByServiceType
          )
            .sort((first, second) => {
              first.billMonth = formatBillMonth(first.billMonth, true);
              second.billMonth = formatBillMonth(second.billMonth, true);
              return new Date(first.billMonth) - new Date(second.billMonth);
            })
            .map((item) => {
              delete item.steam;
              item.billMonth = formatBillMonth(item.billMonth, true);

              return item;
            });
          commit('SET_ACCOUNT_CHARGES_CHART', accountChargesChart);
          let limit =
            accountChargesChart.length >= 12
              ? accountChargesChart.length - 12
              : accountChargesChart.length - 1;
          for (let i = accountChargesChart.length - 1; i >= limit; i--) {
            account12MonthsTotalCharge += accountChargesChart[i].totalCharges;
          }

          commit('SET_ACCOUNT_TOTAL_CHARGES', account12MonthsTotalCharge);
        }
      })
      .catch((err) => {
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountUsageChart({ commit }, accountId) {
    await axios
      .get(`/accounts/${accountId}/info/totalConsumptionByServiceType`)
      .then((res) => {
        if (res.status === 200) {
          let accountUsagesChart = [];
          deepCopy(res.data.data.totalConsumptionByServiceType)
            .sort(
              (first, second) =>
                new Date(first.billMonth) - new Date(second.billMonth)
            )
            .forEach((item) => {
              // Changed map to forEach since we're not returning anything from the function
              item.billMonth = formatBillMonth(item.billMonth, true);
              let found = accountUsagesChart.find(
                (el) => el.billMonth === item.billMonth
              );

              // Initialize the missingServiceTypeMonth array if it doesn't exist
              if (found && !found.missingServiceTypeMonth) {
                found.missingServiceTypeMonth = [];
              }

              // Check if the current item is missing an invoice
              if (item.isInvoiceMissing) {
                if (found) {
                  // Add the service type to the missing array if it doesn't already exist
                  if (
                    !found.missingServiceTypeMonth.includes(
                      formatServiceType(item.serviceType)
                    )
                  ) {
                    found.missingServiceTypeMonth.push(
                      formatServiceType(item.serviceType)
                    );
                  }
                } else {
                  // Create a new object with the missing service type
                  accountUsagesChart.push({
                    billMonth: item.billMonth,
                    missingServiceTypeMonth: [
                      formatServiceType(item.serviceType)
                    ]
                  });
                  // Since we just added it, set found to this new object
                  found = accountUsagesChart[accountUsagesChart.length - 1];
                }
              }

              if (found) {
                // Add the totalUsage to the existing month
                found[item.serviceType] = item.totalUsage;

                // Add other properties if the service type is 'electric'
                if (item.serviceType === 'electric') {
                  found['kVA'] = item.maxKvA;
                  found['kW'] = item.maxkW;
                  found['kVAR'] = item.totalKvAR;
                }
              } else {
                // Create a new object for this month and service type
                const newUsage = {
                  billMonth: item.billMonth,
                  [item.serviceType]: item.totalUsage,
                  missingServiceTypeMonth: item.isInvoiceMissing
                    ? [formatServiceType(item.serviceType)]
                    : []
                };

                // Add other properties if the service type is 'electric'
                if (item.serviceType === 'electric') {
                  newUsage['kVA'] = item.maxKvA;
                  newUsage['kW'] = item.maxkW;
                  newUsage['kVAR'] = item.totalKvAR;
                }

                accountUsagesChart.push(newUsage);
              }
            });

          commit('SET_ACCOUNT_USAGES_CHART', accountUsagesChart);

          const accountUsagesGrid = res.data.data.totalConsumptionByServiceType
            .filter((item) => !item.isInvoiceMissing)
            .map((item) => {
              item.billMonth = formatSortingBillMonth(item.billMonth);
              item.serviceIcons = replaceServiceType(item.serviceType, true);
              item.services = replaceServiceType(item.serviceType);

              if (item.energyUnit !== 'kWh') {
                item.energyUnit = capitalizeFirstLetter(item.energyUnit);
              }
              return item;
            });
          //Calculate service usage for the last 12 months
          let account12MonthsTotalUsages = {};
          let serviceTypeCount = {};
          let usagesLast12Months = [];
          accountUsagesGrid.forEach((item) => {
            if (!account12MonthsTotalUsages[item.serviceType]) {
              account12MonthsTotalUsages[item.serviceType] = {
                totalUsage: 0,
                serviceIcon: `${item.serviceIcons[0]} card-header-icon`,
                service: item.services,
                energyUnit: item.energyUnit
              };
            }
          });

          Object.keys(account12MonthsTotalUsages).forEach((item) => {
            serviceTypeCount[item] = {
              count: 0
            };
          });

          accountUsagesGrid.forEach((item) => {
            if (serviceTypeCount[item.serviceType].count < 12) {
              usagesLast12Months.push({
                billMonth: item.billMonth,
                serviceType: item.serviceType,
                totalUsage: item.totalUsage
              });
              serviceTypeCount[item.serviceType].count++;
            }
          });
          usagesLast12Months.forEach((item) => {
            account12MonthsTotalUsages[item.serviceType].totalUsage +=
              item.totalUsage;
          });

          commit('SET_ACCOUNT_TOTAL_USAGES', account12MonthsTotalUsages);
          commit('SET_ACCOUNT_USAGES_GRID', accountUsagesGrid);
          commit('SET_ACCOUNT_USAGES_GRID_LOAD', false);
        }
      })
      .catch((err) => {
        commit('SET_ERROR', err.status);
      });
  },
  async setAccountServiceTypes({ commit }) {
    await axios
      .get('/accounts/serviceTypes')
      .then((res) => {
        const serviceTypes = res.data.data.map((item) => {
          item.service = replaceServiceType(item.name);

          return item;
        });

        commit('SET_ACCOUNT_SERVICE_TYPES', serviceTypes);
      })
      .catch((err) => {
        commit('SET_ERROR', err.status);
      });
  },
  async addMeterRecord({ commit }, params) {
    return await axios
      .post('/accounts/addMeterRecord', params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        commit('SET_ERROR', err.status);
      });
  },
  setAccountLoad({ commit }) {
    commit('SET_ACCOUNT_CHARTS_LOAD', true);
    commit('SET_ACCOUNT_CHARGES_GRID_LOAD', true);
    commit('SET_ACCOUNT_USAGES_GRID_LOAD', true);
    commit('SET_ACCOUNT_METERS_GRID_LOAD', true);
  },
  async updateEsiId({ commit }, params) {
    const updatedMeter = {
      id: params.record.id,
      esiId: params.record[`newEsiIdValue${params.recordIdx}`],
      subAddress: params.record[`newSubAddressValue${params.recordIdx}`],
      serviceCity: params.record[`newServiceCityValue${params.recordIdx}`],
      serviceState: params.record[`newServiceStateValue${params.recordIdx}`],
      serviceZip: params.record[`newServiceZipValue${params.recordIdx}`],
      accountEsiId: params.record[`accountEsiId`],
      accountSubAddress: params.record[`accountSubAddress`]
    };
    axios
      .put(`/updateEsiIdSubAddress`, updatedMeter)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  setAccountTotalCharges({ commit }, params) {
    let totalCharges = 0;
    let min = params[0].findIndex((el) => el.billMonth === params[1].firstDate);
    let max = params[0].findIndex(
      (item) => item.billMonth === params[1].secondDate
    );

    for (let i = max; i >= min; i--) {
      totalCharges += params[0][i].totalCharges;
    }
    commit('SET_ACCOUNT_TOTAL_CHARGES', totalCharges);
  },
  setAccountTotalUsages({ commit }, params) {
    let accountTotalUsages = {};
    let usage = {};
    let usagesMonths = [];
    let data = params[0].toReversed();
    let services = [
      'natural_gas',
      'sewer',
      'electric',
      'water',
      'trash',
      'telecom'
    ];

    state.accountUsagesGrid.forEach((item) => {
      if (!accountTotalUsages[item.serviceType]) {
        accountTotalUsages[item.serviceType] = {
          totalUsage: 0,
          serviceIcon: `${item.serviceIcons[0]} card-header-icon`,
          service: item.services,
          energyUnit: item.energyUnit
        };
      }
    });

    let min = data.findIndex((item) => item.billMonth === params[1].firstDate);
    let max = data.findIndex((item) => item.billMonth === params[1].secondDate);

    for (let i = max; i <= min; i++) {
      Object.keys(data[i]).forEach((item) => {
        if (item !== 'billMonth' && services.includes(item)) {
          usage.billMonth = data[i].billMonth;
          usage.serviceType = item;
          usage['totalUsage'] = data[i][item];
          usagesMonths.push(usage);
          usage = {};
        }
      });
    }

    usagesMonths.forEach((item) => {
      accountTotalUsages[item.serviceType].totalUsage += item.totalUsage;
    });
    commit('SET_ACCOUNT_TOTAL_USAGES', accountTotalUsages);
  },
  setAccountTotalChargesDate({ commit }, dateRange) {
    commit(
      'SET_ACCOUNT_TOTAL_CHARGES_DATE',
      dateRange.firstDate + ' - ' + dateRange.secondDate
    );
  },
  setAccountTotalUsagesDate({ commit }, dateRange) {
    commit(
      'SET_ACCOUNT_TOTAL_USAGES_DATE',
      dateRange.firstDate + ' - ' + dateRange.secondDate
    );
  }
};

const getters = {
  accountInfo: (state) => {
    return state.accountInfo;
  },
  accountLatestBillInfo: (state) => {
    return state.accountLatestBillInfo;
  },
  accountChargesChart: (state) => {
    return state.accountChargesChart;
  },
  accountUsagesChart: (state) => {
    return state.accountUsagesChart;
  },
  accountChargesGrid: (state) => {
    return state.accountChargesGrid;
  },
  accountUsagesGrid: (state) => {
    return state.accountUsagesGrid;
  },
  accountServiceTypes: (state) => {
    return state.accountServiceTypes;
  },
  accountMeters: (state) => {
    return state.accountMeters;
  },
  isAccountChartsLoad: (state) => {
    return state.isAccountChartsLoad;
  },
  isAccountChargesGridLoad: (state) => {
    return state.isAccountChargesGridLoad;
  },
  isAccountUsagesGridLoad: (state) => {
    return state.isAccountUsagesGridLoad;
  },
  isAccountMetersGridLoad: (state) => {
    return state.isAccountMetersGridLoad;
  },
  accountTotalCharges: () => {
    return state.accountTotalCharges;
  },
  accountTotalUsages: () => {
    return state.accountTotalUsages;
  },
  accountTotalChargesDate: (state) => {
    return state.accountTotalChargesDate;
  },
  accountTotalUsagesDate: (state) => {
    return state.accountTotalUsagesDate;
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
