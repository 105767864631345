<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
      v-show="this.$route.path !== '/consultant'"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.home'),
            icon: 'fa fa-home',
            path: userLogged.isConsultant ? '/consultant' : '/home'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.locations'),
            icon: 'fa fa-location-dot',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/locations`
              : '/locations'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.accounts'),
            icon: 'fa fa-laptop',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/accounts`
              : '/accounts'
          }"
        >
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.bills'),
            icon: 'fa fa-envelope',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/bills`
              : '/bills'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="
            userLogged.isConsultant ||
            (isAlertsClient && !userLogged.isLimitedClient)
          "
          :link="{
            name: $t('sidebar.alerts'),
            icon: 'fa fa-bell',
            path: alertsPath
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="!userLogged.isLimitedClient"
          :link="{
            name: $t('sidebar.contracts'),
            icon: 'fa fa-file',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/contracts`
              : '/contracts'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="
            userLogged.isConsultant ||
            (isBudgetsClient && !userLogged.isLimitedClient)
          "
          :link="{
            name: $t('sidebar.budgets'),
            icon: 'fa fa-scale-balanced',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/budgets`
              : '/budgets'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="showApPage"
          :link="{
            name: $t('sidebar.ap'),
            icon: 'fa fa-dollar-sign sidebar-bill-deliveries-icon',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/billdeliveries`
              : '/billdeliveries'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="isBillPayClient && !userLogged.isLimitedClient"
          :link="{
            name: $t('sidebar.billpay'),
            icon: 'fa fa-money-check',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/billpay`
              : '/billpay'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="!userLogged.isLimitedClient"
          :link="{
            name: $t('sidebar.reports'),
            icon: 'fa fa-chart-simple',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/reports`
              : '/reports'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="isOpenCloseClient && !userLogged.isLimitedClient"
          :link="{
            name: $t('sidebar.opencloseaccounts'),
            icon: 'fas fa-clipboard-list-check',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/opencloseaccounts`
              : '/opencloseaccounts'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="isEnergyStarClient"
          :link="{
            name: $t('sidebar.companyenergystar'),
            icon: 'fas fa-star',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/companyEnergyStar`
              : '/companyEnergyStar'
          }"
        ></sidebar-item>
        <sidebar-item
          v-show="companyHasSupportAccess"
          :link="{
            name: $t('sidebar.companysupport'),
            icon: 'fa fa-solid fa-user-headset',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/tickets`
              : '/tickets'
          }"
        >
        </sidebar-item>
        <sidebar-item
          v-show="userLogged.isSuperConsultant"
          :link="{
            name: $t('sidebar.users'),
            icon: 'fa fa-users',
            path: `/companies/${userLogged.compId}/users`
          }"
        ></sidebar-item>
        <sidebar-item
          v-show="userLogged.isConsultant"
          :link="{
            name: $t('sidebar.inviteuser'),
            icon: 'fa fa-user-plus',
            path: userLogged.isConsultant
              ? `/companies/${userLogged.compId}/inviteuser`
              : '/inviteuser'
          }"
        ></sidebar-item>
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <router-view></router-view>
        </zoom-center-transition>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className, scrollSpeed = 1) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`, {
      wheelSpeed: scrollSpeed
    });
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className, scrollSpeed);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    DashboardNavbar,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition
  },
  data() {
    return {
      sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    userLogged() {
      return {
        compId: this.$route.params.compId,
        isSuperConsultant: this.$store.getters.isSuperConsultant,
        isConsultant: this.$store.getters.isConsultant,
        isLimitedClient: this.$store.getters.isLimitedClient
      };
    },
    //Check if the client has apAccess
    isApClient() {
      return this.$store.getters.getCompany.apAccess;
    },
    isBillPayClient() {
      return this.$store.getters.getCompany.billPayAccess;
    },
    isBudgetsClient() {
      return this.$store.getters.getCompany.budgetsAccess;
    },
    isAlertsClient() {
      return this.$store.getters.getCompany.alertsAccess;
    },
    isOpenCloseClient() {
      return this.$store.getters.getCompany.openCloseAccess;
    },
    isEnergyStarClient() {
      return this.$store.getters.getCompany.energyStarAccess;
    },
    showApPage() {
      return this.userLogged.isConsultant || this.isApClient;
    },
    companyHasSupportAccess() {
      return this.$store.getters.getCompany.companyHasSupportAccess;
    },
    alertsPath() {
      const isConsultant = this.userLogged.isConsultant;
      if (isConsultant) {
        return `/companies/${this.userLogged.compId}/alertsro`;
      } else {
        return '/alertsro';
      }
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel', 0.5);
        initScrollbar('sidebar-wrapper', 0.1);

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  mounted() {
    this.initScrollbar();
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
